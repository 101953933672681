import React from "react";
import PhoneIcon from "../assets/images/phone_icon_white.png";

const AppFooter = (props) => {
  return (
    <footer className="arlas-cs-footer">
      <nav>
        <ul>
          <li>
            &copy;2020 TGS&nbsp;
            <span className="footer-pipe">|</span>
          </li>
          <li>
            <a
              href="https://www.tgs.com/trademark"
              rel="noopener noreferrer"
              target="_blank"
            >
              Trademark
            </a>
            &nbsp;
            <span className="footer-pipe">|</span>
          </li>
          <li>
            <a
              href="https://www.tgs.com/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
            &nbsp;
            <span className="footer-pipe">|</span>
          </li>
          <li>
            <a
              href="https://www.tgs.com/terms-and-conditions"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms & Conditions
            </a>
            &nbsp;
            <span className="footer-pipe">|</span>
          </li>
          <li>
            <a
              href="https://tgs.maps.arcgis.com/sharing/rest/content/items/1e21822ccc134739a9122341e73d2e87/data"
              rel="noopener noreferrer"
              target="_blank"
            >
              Sources
            </a>
            &nbsp;
            <span className="footer-pipe">|</span>
          </li>
          <li className="contact-us">
            <a
              href="https://www.tgs.com/contact-us"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={PhoneIcon} className="phone-icon" alt="phone icon" />
              <span>Contact Us</span>
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default AppFooter;
