import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Security, SecureRoute, ImplicitCallback } from "@okta/okta-react";
import config from "./configs/appSettings";
import AccessDenied from "./pages/AccessDenied";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/scss/main.scss";
import Map from "./pages/Map";
import MapWithoutOkta from "./pages/MapDemo";

const { issuer, clientId, scope } = config.oidc;
const callbackPath = "/implicit-callback";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Security
            issuer={issuer}
            client_id={clientId}
            scope={scope}
            redirect_uri={window.location.origin + callbackPath}
          >
            <SecureRoute path="/map" exact component={Map} />
            <Route path="/" exact component={MapWithoutOkta} />
            <Route path={callbackPath} component={ImplicitCallback} />
            <Route path="/access-denied" component={AccessDenied} />
          </Security>
        </Router>
      </div>
    );
  }
}

export default App;
