import React, { useEffect } from "react";
//import MapInputPanel from "../components/MapInputPanel";
import Mapgenerator from "./MapGenerator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MapArea(props) {
  const CustomToastIntroMsg = () => (
    <div className="introMsg-container">
      <div className="introMsg-title">Welcome to the TGS Wind Pathfinder</div>
      <div className="introMsg-text">Here you can:</div>
      <div className="introMsg-text">
        -View offshore wind farm leases and turbine locations
      </div>
      <div className="introMsg-text">-View onshore windfarm locations</div>
      <div className="introMsg-text">
        -Explore these relationships with wind power distribution
      </div>
    </div>
  );
  useEffect(() => {
    toast.success(CustomToastIntroMsg, { containerId: "introMsg" });
  }, []);
  return (
    <section className="arlas-cs-map-section">
      {/* Base map with  layer */}
      <Mapgenerator />
      <ToastContainer
        enableMultiContainer
        containerId={"introMsg"}
        position="bottom-right"
        autoClose={20000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default MapArea;
