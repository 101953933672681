export default {
  name: "default",
  oidc: {
    clientId: "aaa",
    issuer: "https://login-dev.tgsnopec.com/oauth2/auspth6n0r1gXj1tV0h7", // verify the issuer is correct
    redirectUri: "http://localhost:8000/implicit/callback",
    scope: ["openid", "profile", "email", "address", "phone", "offline_access"],
    allowedGroups: [],
  },
  endpoints: {
    WellLocationCache:
      "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Standard/WellLocationCacheMultiLayer/MapServer",
    WellLocationCacheProxy:
      "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx",

    basinLayer:
      "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Premium/TGS_Formations/MapServer",
    basinLayerProxy: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx",
    SesimicSurvey:
      "https://map.tgs.com/arcgis/rest/services/web/Map_NoWells_Dynamic/MapServer",
    SesimicSurveyProxy: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx",

    BTMPermianUrl:
      "https://map.tgs.com/arcgis/rest/services/web/selection/MapServer/5",

    windFarmDatabaseUrl:
      "https://services1.arcgis.com/Vlw77QWOmRIp56YB/arcgis/rest/services/WPPDB_Wind_gdb/FeatureServer/0",
    windTurbineDatabaseUrl:
      "https://services1.arcgis.com/Vlw77QWOmRIp56YB/arcgis/rest/services/US_Wind_Turbine_Database/FeatureServer/0",

    ElectricPowerTransmission:
      "https://services1.arcgis.com/Vlw77QWOmRIp56YB/arcgis/rest/services/USA_Power_Transmission_Lines/FeatureServer/0",

    OffshoreWindPFLA:
      "https://services1.arcgis.com/Vlw77QWOmRIp56YB/arcgis/rest/services/Offshore_Wind_Planned_Future_License_Areas/FeatureServer/0",

    OffshoreWindAIOUC:
      " https://services1.arcgis.com/Vlw77QWOmRIp56YB/arcgis/rest/services/Offshore_Wind_Active_Licenses/FeatureServer/0",

    OffshoreWindAppInPlace:
      "https://services1.arcgis.com/Vlw77QWOmRIp56YB/arcgis/rest/services/Offshore_Wind_License_Applications/FeatureServer/0",

    OffshoreWindScopingPotential:
      "https://services1.arcgis.com/Vlw77QWOmRIp56YB/arcgis/rest/services/Offshore_Wind_Scoping_Areas/FeatureServer/0",

    MeanWindPowerDensity:
      "https://tiles.arcgis.com/tiles/Vlw77QWOmRIp56YB/arcgis/rest/services/Power_Density_100m/MapServer",

    OffshoreWindTurbineEurope:
      "https://services1.arcgis.com/Vlw77QWOmRIp56YB/arcgis/rest/services/European_Offshore_Wind_Turbine_locations/FeatureServer/0",
    lightGrayCanvasRef:
      "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer",
    lightGrayCanvasBase:
      "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer",
    lightGraythumbnailImage:
      "https://js.arcgis.com/4.16/esri/images/basemap/gray-vector.jpg",

    nationalGeographicBase:
      "https://services.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer",
    nationalGeographicBasethumbNailImage:
      "https://js.arcgis.com/4.16/esri/images/basemap/national-geographic.jpg",

    topographicRef:
      "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer",
    topographicBase:
      "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer",
    topographicBasethumbNailImage:
      "https://js.arcgis.com/4.16/esri/images/basemap/topo-vector.jpg",

    worldImageryLabelsRef:
      "https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer",
    worldImageryLabelsBase:
      "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
    worldImageryLabelsBaseThumbNailImage:
      "https://js.arcgis.com/4.16/esri/images/basemap/hybrid.jpg",
    HelpGuideurl:
      "https://storymaps.arcgis.com/stories/49202cd010914a489394a3997f82d0eb",
  },
};
