import React from "react";
import MapArea from "../components/MapArea";
import AppLayout from "../components/AppLayout";
import { withAuth } from "@okta/okta-react";

function MapWithoutOkta(props) {
  return (
    <AppLayout>
      <MapArea />
      {/*maparea contain mapinput panel and base map with well layers */}
    </AppLayout>
  );
}

export default withAuth(MapWithoutOkta);
