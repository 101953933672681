import React from "react";
import { loadModules } from "esri-loader";
import { setDefaultOptions } from "esri-loader";
import { loadCss } from "esri-loader";
import config from "../configs/appSettings";
import arrowClose from "../assets/images/icons/arrow-close.svg";
import arrowOpen from "../assets/images/icons/arrow-open.svg";
/* eslint-disable */
// For Loading Map Version (3.31) from latest
loadCss("3.31");
setDefaultOptions({ version: "3.31" });

var mapboxBlock = "";
var mapSetState ="";
let windFarmDatabaseLayer;
let windTurbineDatabaseLayer;
let electricPowerTransmissionLayer;
let offshoreWindPFLALayer;
let offshoreWindAIOUCLayer;
let meanWindPowerDensityLayer;
let offshoreWindAppInPlaceLayer;
let offshoreWindScopingPotentialLayer;
let offshoreWindTurbineEuropeLayer;
export default class MapGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBaseMap: false,
      zoomState: false,
      zoomItemText: 5,
      drawIconState: "#00000080",
      drawBoundingIconState: "#00000080",
      layerOpacity: 0.75,
      layerTabData: true,
      legendTabData: false,
      layerBlock: "block",
      legendBlock: "none",

      wellLegendSlider: "none",

      legendTgsBasinSlider: "none",
      legend2DInProgressSurveyLines: "none",
      legend2DInCompletedSurveyLines: "none",
      legend2DOtherSurveyLines: "none",

      legend3DInProgressSurveyLines: "none",
      legend3DCompletedSurvey: "none",
      legendBTMmodel: "none",
      legendWindFarmDatabase: "block",
      legendWindTurbineDatabase: "block",
      legendElectricPowerTransmission: "none",
      legendOffshoreWindPFLA: "block",
      legendOffshoreWindAIOUC: "block",
      legendMeanWindPowerDensity: "block",
      legendOffshoreWindAppInPlace: "block",
      legendOffshoreWindScopingPotential: "block",
      legendOffshoreWindTurbineEurope: "block",

      wellSliderValue: 25,

      legendTgsBasinSliderValue: 25,
      legend2DInProgressSurveyLinesValue: 25,
      legend2DInCompletedSurveyLinesValue: 25,
      legend2DOtherSurveyLinesValue: 25,

      legend3DInProgressSurveyLinesValue: 25,
      legend3DCompletedSurveyValue: 25,
      legendBTMmodelValue: 25,
      legendWindFarmDatabaseValue: 25,
      legendWindTurbineDatabaseValue: 25,
      legendOffshoreWindPFLAValue: 25,
      legendOffshoreWindAIOUCValue: 25,
      legendElectricPowerTransmissionValue: 25,
      legendMeanWindPowerDensityValue: 25,
      legendOffshoreWindAppInPlaceValue: 25,
      legendOffshoreWindScopingPotentialValue: 25,
      legendOffshoreWindTurbineEuropeValue: 25,

      layerObject: {
        layerWidth: "22%",
        mapBoxWidth: "78%",
        layerFloat: "left",
        layerHeight: "93.5vh",
        layerArrowLeft: "22%",
        layerArrow: arrowOpen,
        layerList: "block",
      },
    };

    this.mapRef = React.createRef();
    this.mapbox = React.createRef();
    this.customZoomOut = React.createRef();
    this.customZoomIn = React.createRef();
    this.defaultZoomref = React.createRef();
    this.lineRef = React.createRef();
    this.deletelineRef = React.createRef();
    this.boundingBoxRef = React.createRef();

    //Greyout Ref
    this.windFarmDatabasegreyOutRef = React.createRef();
this.windTurbineDatabasegreyOutRef = React.createRef();
this.electricPowerTransmissiongreyOutRef = React.createRef();
 this.offshoreWindPFLALayergreyOutRef = React.createRef();
 this.offshoreWindAIOUCgreyOutRef = React.createRef();
 this.meanWindPowerDensitygreyOutRef = React.createRef();
  this.offshoreWindAppInPlacegreyOutRef = React.createRef();
   this.offshoreWindScopingPotentialgreyOutRef = React.createRef();
   this.offshoreWindTurbineEuropegreyOutRef = React.createRef();
  }

  static getDerivedStateFromProps(props) {
    loadModules(["esri/geometry/Point"]).then(([Point]) => {
      var refMapbox = mapboxBlock;
      const resetToMap = () => {
        var point = new Point(-94.08761370000047, 29.538211358412124);
        refMapbox.centerAndZoom(point, 5);
      };

       //windFarmDatabaseLayer GreyOut Layer Start
       if (refMapbox.__LOD !== undefined) {
        setTimeout(() => {
          if (windFarmDatabaseLayer.minScale !== 0) {
          if (refMapbox.__LOD.scale <= windFarmDatabaseLayer.minScale) {
            mapSetState.windFarmDatabasegreyOutRef.current.style.opacity = "1";

            if (refMapbox.__LOD.scale >= windFarmDatabaseLayer.maxScale) {
              mapSetState.windFarmDatabasegreyOutRef.current.style.opacity = "1";
            } else {
              mapSetState.windFarmDatabasegreyOutRef.current.style.opacity = "0.3";
            }
          } else {
            mapSetState.windFarmDatabasegreyOutRef.current.style.opacity = "0.3";
          }
        }
        }, 1000);
      }
      //windFarmDatabaseLayer GreyOut Layer End
       //windTurbineDatabaseLayer GreyOut Layer Start
       if (refMapbox.__LOD !== undefined) {
        setTimeout(() => {
          if (windTurbineDatabaseLayer.minScale !== 0) {
          if (refMapbox.__LOD.scale <= windTurbineDatabaseLayer.minScale) {
            mapSetState.windTurbineDatabasegreyOutRef.current.style.opacity = "1";

            if (refMapbox.__LOD.scale >= windTurbineDatabaseLayer.maxScale) {
              mapSetState.windTurbineDatabasegreyOutRef.current.style.opacity = "1";
            } else {
              mapSetState.windTurbineDatabasegreyOutRef.current.style.opacity = "0.3";
            }
          } else {
            mapSetState.windTurbineDatabasegreyOutRef.current.style.opacity = "0.3";
          }
        }
        }, 1000);
      }
      //windTurbineDatabaseLayer GreyOut Layer End

       //electricPowerTransmissionLayer GreyOut Layer Start
       if (refMapbox.__LOD !== undefined) {
        setTimeout(() => {
          if (electricPowerTransmissionLayer.minScale !== 0) {
          if (refMapbox.__LOD.scale <= electricPowerTransmissionLayer.minScale) {
            mapSetState.electricPowerTransmissiongreyOutRef.current.style.opacity = "1";

            if (refMapbox.__LOD.scale >= electricPowerTransmissionLayer.maxScale) {
              mapSetState.electricPowerTransmissiongreyOutRef.current.style.opacity = "1";
            } else {
              mapSetState.electricPowerTransmissiongreyOutRef.current.style.opacity = "0.3";
            }
          } else {
            mapSetState.electricPowerTransmissiongreyOutRef.current.style.opacity = "0.3";
          }
        }
        }, 1000);
      }
      //electricPowerTransmissionLayer GreyOut Layer End


      //offshoreWindPFLALayer GreyOut Layer Start
      if (refMapbox.__LOD !== undefined) {
        setTimeout(() => {
          if (offshoreWindPFLALayer.minScale !== 0) {
          if (refMapbox.__LOD.scale <= offshoreWindPFLALayer.minScale) {
            mapSetState.offshoreWindPFLALayergreyOutRef.current.style.opacity = "1";

            if (refMapbox.__LOD.scale >= offshoreWindPFLALayer.maxScale) {
              mapSetState.offshoreWindPFLALayergreyOutRef.current.style.opacity = "1";
            } else {
              mapSetState.offshoreWindPFLALayergreyOutRef.current.style.opacity = "0.3";
            }
          } else {
            mapSetState.offshoreWindPFLALayergreyOutRef.current.style.opacity = "0.3";
          }
        }
        }, 1000);
      }
      //offshoreWindPFLALayer GreyOut Layer End

      //offshoreWindAIOUCLayer GreyOut Layer Start
      if (refMapbox.__LOD !== undefined) {
        setTimeout(() => {
          if (offshoreWindAIOUCLayer.minScale !== 0) {
          if (refMapbox.__LOD.scale <= offshoreWindAIOUCLayer.minScale) {
            mapSetState.offshoreWindAIOUCgreyOutRef.current.style.opacity = "1";

            if (refMapbox.__LOD.scale >= offshoreWindAIOUCLayer.maxScale) {
              mapSetState.offshoreWindAIOUCgreyOutRef.current.style.opacity = "1";
            } else {
              mapSetState.offshoreWindAIOUCgreyOutRef.current.style.opacity = "0.3";
            }
          } else {
            mapSetState.offshoreWindAIOUCgreyOutRef.current.style.opacity = "0.3";
          }
        }
        }, 1000);
      }
      //offshoreWindAIOUCLayer GreyOut Layer End

      //meanWindPowerDensityLayer GreyOut Layer Start
      if (refMapbox.__LOD !== undefined) {
        setTimeout(() => {
          if (meanWindPowerDensityLayer.minScale !== 0) {
          if (refMapbox.__LOD.scale <= meanWindPowerDensityLayer.minScale) {
            mapSetState.meanWindPowerDensitygreyOutRef.current.style.opacity = "1";

            if (refMapbox.__LOD.scale >= meanWindPowerDensityLayer.maxScale) {
              mapSetState.meanWindPowerDensitygreyOutRef.current.style.opacity = "1";
            } else {
              mapSetState.meanWindPowerDensitygreyOutRef.current.style.opacity = "0.3";
            }
          } else {
            mapSetState.meanWindPowerDensitygreyOutRef.current.style.opacity = "0.3";
          }
        }
        }, 1000);
      }
      //meanWindPowerDensityLayer GreyOut Layer End

       //offshoreWindAppInPlaceLayer GreyOut Layer Start
       if (refMapbox.__LOD !== undefined) {
        setTimeout(() => {
          if (offshoreWindAppInPlaceLayer.minScale !== 0) {
          if (refMapbox.__LOD.scale <= offshoreWindAppInPlaceLayer.minScale) {
            mapSetState.offshoreWindAppInPlacegreyOutRef.current.style.opacity = "1";

            if (refMapbox.__LOD.scale >= offshoreWindAppInPlaceLayer.maxScale) {
              mapSetState.offshoreWindAppInPlacegreyOutRef.current.style.opacity = "1";
            } else {
              mapSetState.offshoreWindAppInPlacegreyOutRef.current.style.opacity = "0.3";
            }
          } else {
            mapSetState.offshoreWindAppInPlacegreyOutRef.current.style.opacity = "0.3";
          }
        }
        }, 1000);
      }
      //offshoreWindAppInPlaceLayer GreyOut Layer End

      //offshoreWindScopingPotentialLayer GreyOut Layer Start
      if (refMapbox.__LOD !== undefined) {
        setTimeout(() => {
          if (offshoreWindScopingPotentialLayer.minScale !== 0) {
          if (refMapbox.__LOD.scale <= offshoreWindScopingPotentialLayer.minScale) {
            mapSetState.offshoreWindScopingPotentialgreyOutRef.current.style.opacity = "1";

            if (refMapbox.__LOD.scale >= offshoreWindScopingPotentialLayer.maxScale) {
              mapSetState.offshoreWindScopingPotentialgreyOutRef.current.style.opacity = "1";
            } else {
              mapSetState.offshoreWindScopingPotentialgreyOutRef.current.style.opacity = "0.3";
            }
          } else {
            mapSetState.offshoreWindScopingPotentialgreyOutRef.current.style.opacity = "0.3";
          }
        }
        }, 1000);
      }
      //offshoreWindScopingPotentialLayer GreyOut Layer End

       //offshoreWindTurbineEuropeLayer GreyOut Layer Start
       if (refMapbox.__LOD !== undefined) {
        setTimeout(() => {
          if (offshoreWindTurbineEuropeLayer.minScale !== 0) {
          if (refMapbox.__LOD.scale <= offshoreWindTurbineEuropeLayer.minScale) {
            mapSetState.offshoreWindTurbineEuropegreyOutRef.current.style.opacity = "1";

            if (refMapbox.__LOD.scale >= offshoreWindTurbineEuropeLayer.maxScale) {
              mapSetState.offshoreWindTurbineEuropegreyOutRef.current.style.opacity = "1";
            } else {
              mapSetState.offshoreWindTurbineEuropegreyOutRef.current.style.opacity = "0.3";
            }
          } else {
            mapSetState.offshoreWindTurbineEuropegreyOutRef.current.style.opacity = "0.3";
          }
        }
        }, 1000);
      }
      //offshoreWindTurbineEuropeLayer GreyOut Layer End
    });

    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  // Toggle more level zoom option function start
  zoomPanelToggle = () => {
    this.setState({ zoomState: !this.state.zoomState });
  };
  // Toggle more level zoom option function end

  // Plus icon zoom option function start
  zoomSinglePlus = (e) => {
    let selectedZoom = e.currentTarget;
    if (selectedZoom.getAttribute("zoom") <= 15) {
      this.setState({ zoomItemText: selectedZoom.getAttribute("zoom") });
    }
  };
  // Plus icon zoom option function end

  // Minus icon zoom option function start
  zoomSingleMinus = (e) => {
    let selectedZoom = e.currentTarget;
    if (selectedZoom.getAttribute("zoom") >= 3) {
      this.setState({ zoomItemText: selectedZoom.getAttribute("zoom") });
    }
  };

  // default zoom option function start
  defaultZoomView = (e) => {
    this.setState({ zoomItemText: 5 });
    this.setState({ zoomState: false });
  };
  // default zoom option function end

  toggleLayer = (e) => {
    if (this.state.layerObject.layerWidth === null) {
      this.setState({
        layerObject: {
          layerWidth: "22%",
          mapBoxWidth: "78%",
          layerFloat: "left",
          layerHeight: "93.5vh",
          layerArrowLeft: "22%",
          layerArrow: arrowOpen,
          layerList: "block",
        },
      });
    } else {
      this.setState({
        layerObject: {
          layerWidth: null,
          mapBoxWidth: null,
          layerFloat: null,
          layerHeight: null,
          layerArrowLeft: null,
          layerArrow: arrowClose,
          layerList: "none",
        },
      });
    }
  };

  //page load
  componentDidMount() {
    loadModules([
      "esri/map",
      "esri/urlUtils",
      "esri/layers/ArcGISTiledMapServiceLayer",
      "esri/layers/ArcGISDynamicMapServiceLayer",
      "esri/Color",
      "esri/toolbars/draw",
      "esri/geometry/webMercatorUtils",
      "esri/symbols/SimpleLineSymbol",
      "esri/graphic",
      "esri/symbols/SimpleFillSymbol",
      "esri/layers/LayerDrawingOptions",
      "esri/renderers/SimpleRenderer",
      "esri/layers/WMSLayer",
      "esri/layers/WMSLayerInfo",
      "esri/tasks/QueryTask",
      "esri/tasks/query",
      "esri/geometry/geometryEngine",
      "esri/geometry/Extent",
      "esri/layers/FeatureLayer",
      "esri/InfoTemplate",
      "esri/dijit/Legend",
      "esri/dijit/BasemapGallery",
    ]).then(
      ([
        Map,
        urlUtils,
        ArcGISTiledMapServiceLayer,
        ArcGISDynamicMapServiceLayer,
        Color,
        Draw,
        webMercatorUtils,
        SimpleLineSymbol,
        Graphic,
        SimpleFillSymbol,
        LayerDrawingOptions,
        SimpleRenderer,
        WMSLayer,
        WMSLayerInfo,
        QueryTask,
        Query,
        geoEng,
        Extent,
        FeatureLayer,
        InfoTemplate,
        Legend,
        BasemapGallery,
      ]) => {
        this.mapbox = new Map(this.mapRef.current, {
          basemap: "gray",
          center: [-94.08761370000047, 29.538211358412124],
          zoom: 5,
          minZoom: 3,
          maxZoom: 15,
          showLabels: true,
        });
        mapboxBlock = this.mapbox;

        mapSetState = this;

       const mousewheelZoom = (mousezoom) => {
         mapSetState.setState({ zoomItemText: mousezoom });
        };

        mapboxBlock.on("mouse-wheel",function(){
          setTimeout(() => {
            mousewheelZoom(mapboxBlock.getZoom())
          }, 1000);
         
          
        })

        //basemap gallery start
        var basemaps = [];
        var lightGrayCanvasRef = new esri.dijit.BasemapLayer({
          url: `${config.endpoints.lightGrayCanvasRef}`,
        });
        var lightGrayCanvasBase = new esri.dijit.BasemapLayer({
          url: `${config.endpoints.lightGrayCanvasBase}`,
        });
        var lightGrayCanvas = new esri.dijit.Basemap({
          layers: [lightGrayCanvasRef, lightGrayCanvasBase],
          title: "Light Gray Canvas",
          thumbnailUrl: `${config.endpoints.lightGraythumbnailImage}`,
        });
        basemaps.push(lightGrayCanvas);

        var nationalGeographicBase = new esri.dijit.BasemapLayer({
          url: `${config.endpoints.nationalGeographicBase}`,
        });

        var nationalGeographic = new esri.dijit.Basemap({
          layers: [nationalGeographicBase],
          title: "National Geographic",
          thumbnailUrl: `${config.endpoints.nationalGeographicBasethumbNailImage}`,
        });
        basemaps.push(nationalGeographic);

        var topographicRef = new esri.dijit.BasemapLayer({
          url: `${config.endpoints.topographicRef}`,
        });
        var topographicBase = new esri.dijit.BasemapLayer({
          url: `${config.endpoints.topographicBase}`,
        });
        var topographic = new esri.dijit.Basemap({
          layers: [topographicRef, topographicBase],
          title: "Topographic",
          thumbnailUrl: `${config.endpoints.topographicBasethumbNailImage}`,
        });
        basemaps.push(topographic);

        var worldImageryLabelsRef = new esri.dijit.BasemapLayer({
          url: `${config.endpoints.worldImageryLabelsRef}`,
        });
        var worldImageryLabelsBase = new esri.dijit.BasemapLayer({
          url: `${config.endpoints.worldImageryLabelsBase}`,
        });
        var worldImageryLabels = new esri.dijit.Basemap({
          layers: [worldImageryLabelsRef, worldImageryLabelsBase],
          title: "Imagery with Labels",
          thumbnailUrl: `${config.endpoints.worldImageryLabelsBaseThumbNailImage}`,
        });
        basemaps.push(worldImageryLabels);

        var basemapGallery = new BasemapGallery(
          {
            showArcGISBasemaps: false,
            basemaps: basemaps,
            map: this.mapbox,
          },
          "basemapGalleryDiv"
        );
        basemapGallery.startup();

        //basemap gallery end

        //Added proxy url Start

        urlUtils.addProxyRule({
          urlPrefix: `${config.endpoints.WellLocationCache}`,

          proxyUrl: `${config.endpoints.WellLocationCacheProxy}`,
        });
        urlUtils.addProxyRule({
          urlPrefix: `${config.endpoints.basinLayer}`,

          proxyUrl: `${config.endpoints.basinLayerProxy}`,
        });
        urlUtils.addProxyRule({
          urlPrefix: `${config.endpoints.SesimicSurvey}`,

          proxyUrl: `${config.endpoints.SesimicSurveyProxy}`,
        });
        urlUtils.addProxyRule({
          urlPrefix: `${config.endpoints.BTMPermianUrl}`,

          proxyUrl: `${config.endpoints.SesimicSurveyProxy}`,
        });

        //Added proxy url End

        //Wind Layer Start
        //windFarmDatabaseLayer Start
        
        const addwindFarmDatabase = () => {
          let windFarmDatabaseUrl = `${config.endpoints.windFarmDatabaseUrl}`;
          var content = "<br><b>Name</b>: ${name}" +
  "<br><b>Capacity_MW</b>: ${capacity_mw}" +
  "<br><b>Operator</b>: ${Operator}" ;
          let winformInfoTemplate = new InfoTemplate("${name}", content);
          windFarmDatabaseLayer = new FeatureLayer(windFarmDatabaseUrl, {
          outFields: ["*"],
            infoTemplate: winformInfoTemplate,
          });
          this.mapbox.addLayer(windFarmDatabaseLayer);
          //Grey Out Start
          setTimeout(() => {
            if (windFarmDatabaseLayer.minScale !== 0) {
              if (
                this.mapbox.getScale() <= windFarmDatabaseLayer.minScale
              ) {
                this.windFarmDatabasegreyOutRef.current.style.opacity = "1";
              } else {
                this.windFarmDatabasegreyOutRef.current.style.opacity = "0.3";
              }
            }
          }, 1000);
          //Grey Out End
          windFarmDatabaseLayer.show();
          let legend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: windFarmDatabaseLayer,
                },
              ],
            },
            "winfarmLegendDiv"
          );
          legend.startup();
          windFarmDatabaseLayer.on("click", function (evt) {
            mapboxBlock.infoWindow.show();
          });
        };
        addwindFarmDatabase();

        this.fnaddwindFarmDatabaseChange = (e) => {
          if (e.currentTarget.checked) {
            windFarmDatabaseLayer.show();
            this.setState({ legendWindFarmDatabase: "block" });
          } else {
            windFarmDatabaseLayer.hide();
            this.setState({ legendWindFarmDatabase: "none" });
          }
        };

        //windFarmDatabaseLayer end

        //windTurbineDatabase Layer Start
        
        const addwindTurbineDatabase = () => {
          let windTurbineDatabaseUrl = `${config.endpoints.windTurbineDatabaseUrl}`;
          windTurbineDatabaseLayer = new FeatureLayer(windTurbineDatabaseUrl);
          this.mapbox.addLayer(windTurbineDatabaseLayer);
           //Grey Out Start
           setTimeout(() => {
            if (windTurbineDatabaseLayer.minScale !== 0) {
              if (
                this.mapbox.getScale() <= windTurbineDatabaseLayer.minScale
              ) {
                this.windTurbineDatabasegreyOutRef.current.style.opacity = "1";
              } else {
                this.windTurbineDatabasegreyOutRef.current.style.opacity = "0.3";
              }
            }
          }, 1000);
          //Grey Out End
          windTurbineDatabaseLayer.show();
        };
        addwindTurbineDatabase();

        this.fnaddwindTurbineDatabaseChange = (e) => {
          if (e.currentTarget.checked) {
            windTurbineDatabaseLayer.show();
            this.setState({ legendWindTurbineDatabase: "block" });
          } else {
            windTurbineDatabaseLayer.hide();
            this.setState({ legendWindTurbineDatabase: "none" });
          }
        };

        //windTurbineDatabase layer end

        //Electric Power Transmission Layer Start
        
        const addelectricPowerTransmission = () => {
          let electricPowerTransmissionUrl = `${config.endpoints.ElectricPowerTransmission}`;
          electricPowerTransmissionLayer = new FeatureLayer(
            electricPowerTransmissionUrl
          );
          this.mapbox.addLayer(electricPowerTransmissionLayer);
           //Grey Out Start
           setTimeout(() => {
            if (electricPowerTransmissionLayer.minScale !== 0) {
              if (
                this.mapbox.getScale() <= electricPowerTransmissionLayer.minScale
              ) {
                this.electricPowerTransmissiongreyOutRef.current.style.opacity = "1";
              } else {
                this.electricPowerTransmissiongreyOutRef.current.style.opacity = "0.3";
              }
            }
          }, 1000);
          //Grey Out End
          electricPowerTransmissionLayer.hide();
          let PowerTransmissionlegend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: electricPowerTransmissionLayer,
                },
              ],
            },
            "PowerTransmissionLegendDiv"
          );
          PowerTransmissionlegend.startup();
        };
        addelectricPowerTransmission();

        this.fnaddelectricPowerTransmissionChange = (e) => {
          if (e.currentTarget.checked) {
            electricPowerTransmissionLayer.show();
            this.setState({ legendElectricPowerTransmission: "block" });
          } else {
            electricPowerTransmissionLayer.hide();
            this.setState({ legendElectricPowerTransmission: "none" });
          }
        };

        //Electric Power Transmission Layer End

        ///OffshoreWindPFLA Layer Start
        
        const addoffshoreWindPFLALayer = () => {
          let offshoreWindPFLAUrl = `${config.endpoints.OffshoreWindPFLA}`;
          offshoreWindPFLALayer = new FeatureLayer(offshoreWindPFLAUrl);
          this.mapbox.addLayer(offshoreWindPFLALayer);
           //Grey Out Start
           setTimeout(() => {
            if (offshoreWindPFLALayer.minScale !== 0) {
              if (
                this.mapbox.getScale() <= offshoreWindPFLALayer.minScale
              ) {
                this.offshoreWindPFLALayergreyOutRef.current.style.opacity = "1";
              } else {
                this.offshoreWindPFLALayergreyOutRef.current.style.opacity = "0.3";
              }
            }
          }, 1000);
          //Grey Out End
          offshoreWindPFLALayer.show();
          let OffshoreWindPFLALegend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: offshoreWindPFLALayer,
                },
              ],
            },
            "OffshoreWindPFLALegendDiv"
          );
          OffshoreWindPFLALegend.startup();
        };
        addoffshoreWindPFLALayer();

        this.fnaddoffshoreWindPFLALayerChange = (e) => {
          if (e.currentTarget.checked) {
            offshoreWindPFLALayer.show();
            this.setState({ legendOffshoreWindPFLA: "block" });
          } else {
            offshoreWindPFLALayer.hide();
            this.setState({ legendOffshoreWindPFLA: "none" });
          }
        };

        ///OffshoreWindPFLA Layer End

        //OffshoreWindAIOUC layer start

        
        const addoffshoreWindAIOUCLayer = () => {
          let offshoreWindAIOUCUrl = `${config.endpoints.OffshoreWindAIOUC}`; 
          var content = "<br><b>Name</b>: ${Name}" +
          "<br><b>Operator</b>: ${Operator}" +
          "<br><b>Cooperator</b>: ${Cooperator}" ;
          let offshoreWindAIOUCInfoTemplate = new InfoTemplate( "${Name}",content);
          offshoreWindAIOUCLayer = new FeatureLayer(offshoreWindAIOUCUrl, {
          outFields: ["*"],         
            infoTemplate: offshoreWindAIOUCInfoTemplate,
          });
          this.mapbox.addLayer(offshoreWindAIOUCLayer);
          //Grey Out Start
          setTimeout(() => {
            if (offshoreWindAIOUCLayer.minScale !== 0) {
              if (
                this.mapbox.getScale() <= offshoreWindAIOUCLayer.minScale
              ) {
                this.offshoreWindAIOUCgreyOutRef.current.style.opacity = "1";
              } else {
                this.offshoreWindAIOUCgreyOutRef.current.style.opacity = "0.3";
              }
            }
          }, 1000);
          //Grey Out End
          offshoreWindAIOUCLayer.show();
          let OffshoreWindAIOUCLegend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: offshoreWindAIOUCLayer,
                },
              ],
            },
            "OffshoreWindAIOUCLegendDiv"
          );
          OffshoreWindAIOUCLegend.startup();          
          offshoreWindAIOUCLayer.on("click", function (evt) {
            mapboxBlock.infoWindow.show();
          });
        };
        addoffshoreWindAIOUCLayer();

        this.fnoffshoreWindAIOUCLayerChange = (e) => {
          if (e.currentTarget.checked) {
            offshoreWindAIOUCLayer.show();
            this.setState({ legendOffshoreWindAIOUC: "block" });
          } else {
            offshoreWindAIOUCLayer.hide();
            this.setState({ legendOffshoreWindAIOUC: "none" });
          }
        };

        //OffshoreWindAIOUC layer End

        //MeanWindPowerDensity function start
        
        const addmeanWindPowerDensityLayer = () => {
          let MeanWindPowerDensityUrl = `${config.endpoints.MeanWindPowerDensity}`;

          meanWindPowerDensityLayer = new ArcGISTiledMapServiceLayer(
            MeanWindPowerDensityUrl
          );
          meanWindPowerDensityLayer.setOpacity(this.state.layerOpacity);
          this.mapbox.addLayer(meanWindPowerDensityLayer);
           //Grey Out Start
           setTimeout(() => {
            if (meanWindPowerDensityLayer.minScale !== 0) {
              if (
                this.mapbox.getScale() <= meanWindPowerDensityLayer.minScale
              ) {
                this.meanWindPowerDensitygreyOutRef.current.style.opacity = "1";
              } else {
                this.meanWindPowerDensitygreyOutRef.current.style.opacity = "0.3";
              }
            }
          }, 1000);
          //Grey Out End
          meanWindPowerDensityLayer.show();

          let MeanWindPowerDensityLegend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: meanWindPowerDensityLayer,
                },
              ],
            },
            "MeanWindPowerDensityLegendDiv"
          );
          MeanWindPowerDensityLegend.startup();
        };
        addmeanWindPowerDensityLayer();

        this.fnaddMeanWindPowerDensityChange = (e) => {
          if (e.currentTarget.checked) {
            meanWindPowerDensityLayer.show();
            this.setState({ legendMeanWindPowerDensity: "block" });
          } else {
            meanWindPowerDensityLayer.hide();
            this.setState({ legendMeanWindPowerDensity: "none" });
          }
        };
        //MeanWindPowerDensity End

        //OffshoreWindAppInPlace layer Start
        
        const addoffshoreWindAppInPlace = () => {
          let offshoreWindAppInPlaceUrl = `${config.endpoints.OffshoreWindAppInPlace}`;
          var content = "<br><b>Name</b>: ${Name}" +
          "<br><b>Operator</b>: ${Operator}";
          let OffshoreWindAppInPlaceInfoTemplate = new InfoTemplate( "${Name}",content);
          offshoreWindAppInPlaceLayer = new FeatureLayer(offshoreWindAppInPlaceUrl, {
          outFields: ["*"],         
            infoTemplate: OffshoreWindAppInPlaceInfoTemplate,
          });        
          //Create Label start
          // var json = {
          //   labelExpressionInfo: { value: "{Status}" },
          // };

          // offshoreWindAppInPlaceLayer.setLabelingInfo([json]);

          //create label end
          this.mapbox.addLayer(offshoreWindAppInPlaceLayer);
          //Grey Out Start
          setTimeout(() => {
            if (offshoreWindAppInPlaceLayer.minScale !== 0) {
              if (
                this.mapbox.getScale() <= offshoreWindAppInPlaceLayer.minScale
              ) {
                this.offshoreWindAppInPlacegreyOutRef.current.style.opacity = "1";
              } else {
                this.offshoreWindAppInPlacegreyOutRef.current.style.opacity = "0.3";
              }
            }
          }, 1000);
          //Grey Out End
          offshoreWindAppInPlaceLayer.show();
          let OffshoreWindAppInPlaceLegend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: offshoreWindAppInPlaceLayer,
                },
              ],
            },
            "OffshoreWindAppInPlaceLegendDiv"
          );
          OffshoreWindAppInPlaceLegend.startup();
        };
        addoffshoreWindAppInPlace();

        this.fnoffshoreWindAppInPlaceChange = (e) => {
          if (e.currentTarget.checked) {
            offshoreWindAppInPlaceLayer.show();
            this.setState({ legendOffshoreWindAppInPlace: "block" });
          } else {
            offshoreWindAppInPlaceLayer.hide();
            this.setState({ legendOffshoreWindAppInPlace: "none" });
          }
        };

        //OffshoreWindAppInPlace layer end

        //OffshoreWindScopingPotential layer start
        
        const addoffshoreWindScopingPotential = () => {
          let OffshoreWindScopingPotentialUrl = `${config.endpoints.OffshoreWindScopingPotential}`;
          offshoreWindScopingPotentialLayer = new FeatureLayer(
            OffshoreWindScopingPotentialUrl
          );
          this.mapbox.addLayer(offshoreWindScopingPotentialLayer);
           //Grey Out Start
           setTimeout(() => {
            if (offshoreWindScopingPotentialLayer.minScale !== 0) {
              if (
                this.mapbox.getScale() <= offshoreWindScopingPotentialLayer.minScale
              ) {
                this.offshoreWindScopingPotentialgreyOutRef.current.style.opacity = "1";
              } else {
                this.offshoreWindScopingPotentialgreyOutRef.current.style.opacity = "0.3";
              }
            }
          }, 1000);
          //Grey Out End
          offshoreWindScopingPotentialLayer.show();
          let OffshoreWindScopingPotentialLegend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: offshoreWindScopingPotentialLayer,
                },
              ],
            },
            "OffshoreWindScopingPotentialLegendDiv"
          );
          OffshoreWindScopingPotentialLegend.startup();
        };
        addoffshoreWindScopingPotential();

        this.fnoffshoreWindScopingPotentialChange = (e) => {
          if (e.currentTarget.checked) {
            offshoreWindScopingPotentialLayer.show();
            this.setState({ legendOffshoreWindScopingPotential: "block" });
          } else {
            offshoreWindScopingPotentialLayer.hide();
            this.setState({ legendOffshoreWindScopingPotential: "none" });
          }
        };

        //OffshoreWindScopingPotential layer End

        //OffshoreWindTurbineEurope Layer Start
        
        const addoffshoreWindTurbineEurope = () => {
          let offshoreWindTurbineEuropeLayerUrl = `${config.endpoints.OffshoreWindTurbineEurope}`;
          offshoreWindTurbineEuropeLayer = new FeatureLayer(
            offshoreWindTurbineEuropeLayerUrl
          );
          this.mapbox.addLayer(offshoreWindTurbineEuropeLayer);
          //Grey Out Start
          setTimeout(() => {
            if (offshoreWindTurbineEuropeLayer.minScale !== 0) {
              if (
                this.mapbox.getScale() <= offshoreWindTurbineEuropeLayer.minScale
              ) {
                this.offshoreWindTurbineEuropegreyOutRef.current.style.opacity = "1";
              } else {
                this.offshoreWindTurbineEuropegreyOutRef.current.style.opacity = "0.3";
              }
            }
          }, 1000);
          //Grey Out End
          offshoreWindTurbineEuropeLayer.show();
        };
        addoffshoreWindTurbineEurope();

        this.fnaddoffshoreWindTurbineEuropeChange = (e) => {
          if (e.currentTarget.checked) {
            offshoreWindTurbineEuropeLayer.show();
            this.setState({ legendOffshoreWindTurbineEurope: "block" });
          } else {
            offshoreWindTurbineEuropeLayer.hide();
            this.setState({ legendOffshoreWindTurbineEurope: "none" });
          }
        };

        //OffshoreWindTurbineEurope layer end

        //Wind Layer End

        //Add 2D seismic Layer Function Start

        //Layer: Completed 2D Survey Lines (ID: 8) Start
        let SeismicLayer8;
        const add2DSeismicLayer8 = () => {
          let seismicUrl = `${config.endpoints.SesimicSurvey}`;
          SeismicLayer8 = new ArcGISDynamicMapServiceLayer(seismicUrl);
          this.mapbox.addLayer(SeismicLayer8);
          SeismicLayer8.setVisibleLayers([8]);
          SeismicLayer8.hide();
          let SeismicLayer8Legend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: SeismicLayer8,
                },
              ],
            },
            "SeismicLayer8LegendDiv"
          );
          SeismicLayer8Legend.startup();
        };
        add2DSeismicLayer8();
        //Layer: Completed 2D Survey Lines (ID: 8) End

        //Layer: In Progress Survey Lines (ID: 7) Start
        let SeismicLayer7;
        const add2DSeismicLayer7 = () => {
          let seismicUrl = `${config.endpoints.SesimicSurvey}`;
          SeismicLayer7 = new ArcGISDynamicMapServiceLayer(seismicUrl);
          this.mapbox.addLayer(SeismicLayer7);
          SeismicLayer7.setVisibleLayers([7]);
          SeismicLayer7.hide();
          let SeismicLayer7Legend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: SeismicLayer7,
                },
              ],
            },
            "SeismicLayer7LegendDiv"
          );
          SeismicLayer7Legend.startup();
        };
        add2DSeismicLayer7();
        //Layer: In Progress Survey Lines (ID: 7) End

        //Layer: Other Survey Lines (ID: 9) Start
        let SeismicLayer9;
        const add2DSeismicLayer9 = () => {
          let seismicUrl = `${config.endpoints.SesimicSurvey}`;
          SeismicLayer9 = new ArcGISDynamicMapServiceLayer(seismicUrl);
          this.mapbox.addLayer(SeismicLayer9);
          SeismicLayer9.setVisibleLayers([9]);
          SeismicLayer9.hide();
          let SeismicLayer9Legend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: SeismicLayer9,
                },
              ],
            },
            "SeismicLayer9LegendDiv"
          );
          SeismicLayer9Legend.startup();
        };
        add2DSeismicLayer9();
        //Layer: Other Survey Lines (ID: 9) End

        //Add 2D seismic Layer Function End

        //2D Seismic Layer Checkbox Onchange Function Start
        this.progress2DSurveyLinesChange = (e) => {
          if (e.currentTarget.checked) {
            if (e.currentTarget.id === "2DprogressSurveyLines") {
              SeismicLayer7.show();
              SeismicLayer8.show();
              this.setState({ legend2DInProgressSurveyLines: "block" });
            } else {
              SeismicLayer9.show();
              this.setState({ legend2DOtherSurveyLines: "block" });
            }
          } else {
            if (e.currentTarget.id === "2DprogressSurveyLines") {
              SeismicLayer7.hide();
              SeismicLayer8.hide();
              this.setState({ legend2DInProgressSurveyLines: "none" });
            } else {
              SeismicLayer9.hide();
              this.setState({ legend2DOtherSurveyLines: "none" });
            }
          }
        };
        //2D Seismic Layer Checkbox Onchange Function End

        //Add 3D seismic Layer Function Start
        //Layer: Completed 3D Seismic Surveys (ID: 5) Fill Start
        let SeismicLayer3D5;
        const add3DSeismicLayer5 = () => {
          let seismic3DUrl = `${config.endpoints.SesimicSurvey}`;
          SeismicLayer3D5 = new ArcGISDynamicMapServiceLayer(seismic3DUrl, {
            opacity: 0.9,
          });
          var layerDrawingOptionPolygon = new LayerDrawingOptions();

          layerDrawingOptionPolygon.transparency = 1;
          var optionsArray = [];
          optionsArray[5] = layerDrawingOptionPolygon;
          SeismicLayer3D5.setLayerDrawingOptions(optionsArray);
          //

          SeismicLayer3D5.setVisibleLayers([5]);
          this.mapbox.addLayer(SeismicLayer3D5);
          SeismicLayer3D5.hide();
          let SeismicLayer3D5Legend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: SeismicLayer3D5,
                },
              ],
            },
            "SeismicLayer3D5LegendDiv"
          );
          SeismicLayer3D5Legend.startup();
        };
        add3DSeismicLayer5();
        //Layer: Completed 3D Seismic Surveys (ID: 5) Fill End

        //Layer: Completed 3D Seismic Surveys (ID: 3) Outline Start
        let SeismicLayer3D3;
        const add3DSeismicLayer3 = () => {
          let seismic3DUrl = `${config.endpoints.SesimicSurvey}`;
          SeismicLayer3D3 = new ArcGISDynamicMapServiceLayer(seismic3DUrl, {
            opacity: 0.9,
          });
          var layerDrawingOptionPolygon = new LayerDrawingOptions();

          layerDrawingOptionPolygon.transparency = 1;
          var optionsArray = [];
          optionsArray[3] = layerDrawingOptionPolygon;
          SeismicLayer3D3.setLayerDrawingOptions(optionsArray);
          SeismicLayer3D3.setVisibleLayers([3]);
          this.mapbox.addLayer(SeismicLayer3D3);
          SeismicLayer3D3.hide();
          let SeismicLayer3D3Legend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: SeismicLayer3D3,
                },
              ],
            },
            "SeismicLayer3D3LegendDiv"
          );
          SeismicLayer3D3Legend.startup();
        };
        add3DSeismicLayer3();
        //Layer: Completed 3D Seismic Surveys (ID: 3) Outline End

        //Layer: In Progress 3D Surveys (ID: 1) Start
        let SeismicLayer3D1;
        const add3DSeismicLayer1 = () => {
          // let seismic3DUrl = `${config.endpoints.SesimicSurvey}`;
          // SeismicLayer3D1 = new ArcGISDynamicMapServiceLayer(seismic3DUrl, {
          //   opacity: 0.9,
          // });
          // var layerDrawingOptionPolygon = new LayerDrawingOptions();

          // layerDrawingOptionPolygon.transparency = 1;
          // var optionsArray = [];
          // optionsArray[1] = layerDrawingOptionPolygon;
          // SeismicLayer3D1.setLayerDrawingOptions(optionsArray);
          // SeismicLayer3D1.setVisibleLayers([1]);
          // this.mapbox.addLayer(SeismicLayer3D1);
          // SeismicLayer3D1.hide();

          //Query for 3D seismic Start
          let seismic3DUrl = `${config.endpoints.SesimicSurvey}`;
          let three3DLayerOptions = {
            id: "three3Dlayer",
            opacity: 0.9,
            showAttribution: false,
          };

          SeismicLayer3D1 = new ArcGISDynamicMapServiceLayer(
            seismic3DUrl,
            three3DLayerOptions
          );
          
          let strFilter = "ProjectSubTypes like '%3D Seismic%'";
          let layerDefAry = [];
          layerDefAry[1] = strFilter;          
          SeismicLayer3D1.setLayerDefinitions(layerDefAry);       
          
          SeismicLayer3D1.setVisibleLayers([1]);
          this.mapbox.addLayer(SeismicLayer3D1);
          SeismicLayer3D1.hide();
          //Query for 3D Seismic End

          let SeismicLayer3D1Legend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: SeismicLayer3D1,
                },
              ],
            },
            "SeismicLayer3D1LegendDiv"
          );
          SeismicLayer3D1Legend.startup();
        };
        add3DSeismicLayer1();
        //Layer: In Progress 3D Surveys (ID: 1) End

        //Add 3D seismic Layer Function End

        //3D Seismic Layer Checkbox Onchange Function Start
        this.progress3DSurveyLinesChange = (e) => {
          if (e.currentTarget.checked) {
            if (e.currentTarget.id === "3DprogressSurveyLines") {
              SeismicLayer3D1.show();
              SeismicLayer3D5.show();
              SeismicLayer3D3.show();
              this.setState({ legend3DInProgressSurveyLines: "block" });
            }
          } else {
            if (e.currentTarget.id === "3DprogressSurveyLines") {
              SeismicLayer3D1.hide();
              SeismicLayer3D5.hide();
              SeismicLayer3D3.hide();
              this.setState({ legend3DInProgressSurveyLines: "none" });
            }
          }
        };
        //3D Seismic Layer Checkbox Onchange Function End

        // well Layer function start
        let wellLayer;
        const addWellCacheLayer = () => {
          let wellShapeUrl = `${config.endpoints.WellLocationCache}`;
          wellLayer = new ArcGISTiledMapServiceLayer(wellShapeUrl);
          wellLayer.setOpacity(this.state.layerOpacity);
          this.mapbox.addLayer(wellLayer);
          wellLayer.hide();
          let welllayerLegend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: wellLayer,
                },
              ],
            },
            "wellLayerLegendDiv"
          );
          welllayerLegend.startup();
        };
        addWellCacheLayer();
        // well Layer function end

        //WellLayer Checkbox Onchange Function Start
        this.addWellChange = (e) => {
          if (e.currentTarget.checked) {
            wellLayer.show();
            this.setState({ wellLegendSlider: "block" });
          } else {
            wellLayer.hide();
            this.setState({ wellLegendSlider: "none" });
          }
        };
        //WellLayer Checkbox Onchange Function End

        /* addBasinLayer function Start */
        var selectedBasinLayer;
        var basinLayer;
        const addBasinLayer = () => {
          var basinShapeUrl = `${config.endpoints.basinLayer}`;

          var basinLayerOptions = {
            id: "basinlayer",
            opacity: 0.3,
            showAttribution: false,
          };

          basinLayer = new ArcGISDynamicMapServiceLayer(
            basinShapeUrl,
            basinLayerOptions
          );
          var strFilter = "NAME IN ('Anadarko Basin', 'Permian Basin')";
          var layerDefAry = [];
          layerDefAry[2] = strFilter;
          var boundaryProjectSymbol = new SimpleFillSymbol(
            SimpleFillSymbol.STYLE_SOLID,
            new SimpleLineSymbol(
              SimpleLineSymbol.STYLE_SOLID,
              new Color([0, 0, 0]),
              2
            ),
            new Color([0, 255, 255, 1])
          );

          var layerDrawingOptionPolygon = new LayerDrawingOptions();
          layerDrawingOptionPolygon.renderer = new SimpleRenderer(
            boundaryProjectSymbol
          );
          var optionsArray = [];
          optionsArray[2] = layerDrawingOptionPolygon;
          basinLayer.setLayerDrawingOptions(optionsArray);
          basinLayer.setVisibleLayers([2]);
          basinLayer.setVisibility(true);
          this.mapbox.addLayer(basinLayer);
          basinLayer.hide();
          let basinLayerLegend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: basinLayer,
                },
              ],
            },
            "basinLayerLegendDiv"
          );
          basinLayerLegend.startup();
        };
        addBasinLayer();
        /* addBasinLayer function End */

        /* addSelectedBasinLayer function start */
        const addSelectedBasinLayer = () => {
          var basinShapeUrl = `${config.endpoints.basinLayer}`;

          var basinLayerOptions = {
            id: "selected-basinlayer",
            opacity: 0.6,
            showAttribution: false,
          };

          selectedBasinLayer = new ArcGISDynamicMapServiceLayer(
            basinShapeUrl,
            basinLayerOptions
          );

          var strFilter = "NAME IN ('Permian Basin')";
          var layerDefAry = [];
          layerDefAry[2] = strFilter;
          selectedBasinLayer.setLayerDefinitions(layerDefAry);
          var boundaryProjectSymbol = new SimpleFillSymbol(
            SimpleFillSymbol.STYLE_SOLID,
            new SimpleLineSymbol(
              SimpleLineSymbol.STYLE_SOLID,
              new Color([0, 0, 0]),
              2
            ),
            new Color([255, 255, 0, 1])
          );
          var layerDrawingOptionPolygon = new LayerDrawingOptions();
          layerDrawingOptionPolygon.renderer = new SimpleRenderer(
            boundaryProjectSymbol
          );
          var optionsArray = [];
          optionsArray[2] = layerDrawingOptionPolygon;
          selectedBasinLayer.setLayerDrawingOptions(optionsArray);
          selectedBasinLayer.setVisibleLayers([2]);
          selectedBasinLayer.setVisibility(true);
          this.mapbox.addLayer(selectedBasinLayer);
          selectedBasinLayer.hide();
        };
        //addSelectedBasinLayer();
        /* addSelectedBasinLayer function End */

        //tgsBasin Checkbox Onchange Function Start
        this.tgsBasinsChange = (e) => {
          if (e.currentTarget.checked) {
            basinLayer.show();
            this.setState({ legendTgsBasinSlider: "block" });
            //selectedBasinLayer.show();
          } else {
            basinLayer.hide();
            //selectedBasinLayer.hide();
            this.setState({ legendTgsBasinSlider: "none" });
          }
        };
        //tgsBasin Checkbox Onchange Function End

        //BTM (Basin Temprature Models) Function Start
        let SeismicBTMLayer;
        let selectedBTMLayer;
        const addBTMLayer = () => {
          let seismicBTMUrl = `${config.endpoints.SesimicSurvey}`;
          SeismicBTMLayer = new ArcGISDynamicMapServiceLayer(seismicBTMUrl);
          SeismicBTMLayer.setVisibleLayers([6]);
          var layerDefinitions = [];
          layerDefinitions[6] = "SurveyName like '%Temperature%'";
          SeismicBTMLayer.setLayerDefinitions(layerDefinitions);
          this.mapbox.addLayer(SeismicBTMLayer);
          SeismicBTMLayer.hide();
          let SeismicBTMLayerLegend = new Legend(
            {
              map: this.mapbox,
              layerInfos: [
                {
                  layer: SeismicBTMLayer,
                },
              ],
            },
            "SeismicBTMLayerLegendDiv"
          );
          SeismicBTMLayerLegend.startup();
        };
        addBTMLayer();
        //BTM (Basin Temprature Models) Function End

        /* addSelectedBTMLayer function start */
        const addSelectedBTMLayer = () => {
          var btmShapeUrl = `${config.endpoints.SesimicSurvey}`;

          var btmLayerOptions = {
            id: "selected-btmlayer",
            opacity: 0.9,
            showAttribution: false,
          };

          selectedBTMLayer = new ArcGISDynamicMapServiceLayer(
            btmShapeUrl,
            btmLayerOptions
          );

          var strFilter = "SurveyName  IN ('Basin Temperature Model Permian')";
          var layerDefAry = [];
          layerDefAry[6] = strFilter;
          selectedBTMLayer.setLayerDefinitions(layerDefAry);
          // var boundaryProjectSymbol = new SimpleFillSymbol(
          //   SimpleFillSymbol.STYLE_SOLID,
          //   new SimpleLineSymbol(
          //     SimpleLineSymbol.STYLE_SOLID,
          //     new Color([0, 0, 0]),
          //     2
          //   ),
          //   new Color([255, 255, 0, 1])

          // );
          var layerDrawingOptionPolygon = new LayerDrawingOptions();
          // layerDrawingOptionPolygon.renderer = new SimpleRenderer(
          //   boundaryProjectSymbol
          // );
          var optionsArray = [];
          optionsArray[6] = layerDrawingOptionPolygon;
          selectedBTMLayer.setLayerDrawingOptions(optionsArray);
          selectedBTMLayer.setVisibleLayers([6]);
          selectedBTMLayer.setVisibility(true);
          this.mapbox.addLayer(selectedBTMLayer);
          selectedBTMLayer.hide();
        };
        addSelectedBTMLayer();
        /* addSelectedBasinLayer function End */

        //BTM (Basin Temprature Models) Checkbox Onchange Function Start
        this.btmLayerChange = (e) => {
          if (e.currentTarget.checked) {
            SeismicBTMLayer.show();
            selectedBTMLayer.show();
            this.setState({ legendBTMmodel: "block" });
          } else {
            SeismicBTMLayer.hide();
            selectedBTMLayer.hide();
            this.setState({ legendBTMmodel: "none" });
          }
        };
        //BTM (Basin Temprature Models) Checkbox Onchange Function End

        //Change Ordering Of Layers End

        //**** Map Zoom Functionality Start*****//

        // minus icon zoom option function start
        this.customZoomOut.current.onclick = () => {
          let zoomlevel = this.mapbox.getZoom() - 1;
          this.customZoomOut.current.setAttribute("zoom", zoomlevel);
          this.mapbox.setZoom(this.mapbox.getZoom() - 1);
        };
        // minus icon zoom option function end

        // plus icon zoom option function start
        this.customZoomIn.current.onclick = () => {
          let zoomlevel = this.mapbox.getZoom() + 1;
          this.customZoomIn.current.setAttribute("zoom", zoomlevel);
          this.mapbox.setZoom(this.mapbox.getZoom() + 1);
        };
        // plus icon zoom option function end
        // default zoom option function start
        this.defaultZoomref.current.onclick = () => {
          this.mapbox.setZoom(5);
        };

        //single level zoom option function start
        this.zoomSingleItem = (e) => {
          let selectedZoom = e.currentTarget;
          this.setState({ zoomItemText: selectedZoom.innerText });
          this.setState({ zoomState: !this.state.zoomState });
          this.mapbox.setZoom(selectedZoom.innerText);
        };
        //single level zoom option function end

        //Change Base maP START
        this.changeBaseMap = (evt) => {
          this.setState({ showBaseMap: !this.state.showBaseMap });
        };
        //change Base Map End
        //**** Map Zoom Functionality End*****//

        //DataLayer and Legend Tab Switch Function Start
        this.dataLayersTabSwitch = (layerObj) => {
          if (layerObj === "data") {
            this.setState({ layerTabData: true });
            this.setState({ legendTabData: false });
            this.setState({ layerBlock: "block" });
            this.setState({ legendBlock: "none" });
          } else {
            this.setState({ layerTabData: false });
            this.setState({ legendTabData: true });
            this.setState({ layerBlock: "none" });
            this.setState({ legendBlock: "block" });
          }
        };
        //DataLayer and Legend Tab Switch Function End

        //Legend Slider Change for (Well and Geothermal Prospector Layers) Function  Start
        this.legendSliderChange = (e) => {
          let sliderValue = e.currentTarget.value;
          let actualSliderValue = 100;
          let totalValue = Math.round(actualSliderValue - sliderValue);
          if (e.currentTarget.id === "wellLegend") {
            if (totalValue === 100) {
              wellLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              wellLayer.setOpacity(`${0}${"."}` + `${0}` + totalValue);
            } else {
              wellLayer.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ wellSliderValue: sliderValue });
          }
          ///
          else if (e.currentTarget.id === "basinsLegend") {
            if (totalValue === 100) {
              basinLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              basinLayer.setOpacity(`${0}${"."}` + `${0}` + totalValue);
            } else {
              basinLayer.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legendTgsBasinSliderValue: sliderValue });
          } else if (e.currentTarget.id === "legend2DInProgressSurveyLines") {
            if (totalValue === 100) {
              SeismicLayer7.setOpacity(1);
              SeismicLayer8.setOpacity(1);
            } else if (sliderValue > 90) {
              SeismicLayer7.setOpacity(`${0}${"."}` + `${0}` + totalValue);
              SeismicLayer8.setOpacity(`${0}${"."}` + `${0}` + totalValue);
            } else {
              SeismicLayer7.setOpacity(`${0}${"."}` + totalValue);
              SeismicLayer8.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legend2DInProgressSurveyLinesValue: sliderValue });
          } else if (e.currentTarget.id === "legend2DOtherSurveyLines") {
            if (totalValue === 100) {
              SeismicLayer9.setOpacity(1);
            } else if (sliderValue > 90) {
              SeismicLayer9.setOpacity(`${0}${"."}` + `${0}` + totalValue);
            } else {
              SeismicLayer9.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legend2DOtherSurveyLinesValue: sliderValue });
          } else if (e.currentTarget.id === "legend3DInProgressSurveyLines") {
            if (totalValue === 100) {
              SeismicLayer3D1.setOpacity(1);
              SeismicLayer3D5.setOpacity(1);
              SeismicLayer3D3.setOpacity(1);
            } else if (sliderValue > 90) {
              SeismicLayer3D1.setOpacity(`${0}${"."}` + `${0}` + totalValue);
              SeismicLayer3D5.setOpacity(`${0}${"."}` + `${0}` + totalValue);
              SeismicLayer3D3.setOpacity(`${0}${"."}` + `${0}` + totalValue);
            } else {
              SeismicLayer3D1.setOpacity(`${0}${"."}` + totalValue);
              SeismicLayer3D5.setOpacity(`${0}${"."}` + totalValue);
              SeismicLayer3D3.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legend3DInProgressSurveyLinesValue: sliderValue });
          } else if (e.currentTarget.id === "legendBTMmodel") {
            if (totalValue === 100) {
              SeismicBTMLayer.setOpacity(1);
              selectedBTMLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              SeismicBTMLayer.setOpacity(`${0}${"."}` + `${0}` + totalValue);
              selectedBTMLayer.setOpacity(`${0}${"."}` + `${0}` + totalValue);
            } else {
              SeismicBTMLayer.setOpacity(`${0}${"."}` + totalValue);
              selectedBTMLayer.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legendBTMmodelValue: sliderValue });
          } else if (e.currentTarget.id === "legendWindFarmDatabase") {
            if (totalValue === 100) {
              windFarmDatabaseLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              windFarmDatabaseLayer.setOpacity(
                `${0}${"."}` + `${0}` + totalValue
              );
            } else {
              windFarmDatabaseLayer.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legendWindFarmDatabaseValue: sliderValue });
          } else if (e.currentTarget.id === "legendOffshoreWindPFLA") {
            if (totalValue === 100) {
              offshoreWindPFLALayer.setOpacity(1);
            } else if (sliderValue > 90) {
              offshoreWindPFLALayer.setOpacity(
                `${0}${"."}` + `${0}` + totalValue
              );
            } else {
              offshoreWindPFLALayer.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legendOffshoreWindPFLAValue: sliderValue });
          } else if (e.currentTarget.id === "legendOffshoreWindAIOUC") {
            if (totalValue === 100) {
              offshoreWindAIOUCLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              offshoreWindAIOUCLayer.setOpacity(
                `${0}${"."}` + `${0}` + totalValue
              );
            } else {
              offshoreWindAIOUCLayer.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legendOffshoreWindAIOUCValue: sliderValue });
          } else if (e.currentTarget.id === "legendWindTurbineDatabase") {
            if (totalValue === 100) {
              windTurbineDatabaseLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              windTurbineDatabaseLayer.setOpacity(
                `${0}${"."}` + `${0}` + totalValue
              );
            } else {
              windTurbineDatabaseLayer.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legendWindTurbineDatabaseValue: sliderValue });
          } else if (e.currentTarget.id === "legendElectricPowerTransmission") {
            if (totalValue === 100) {
              electricPowerTransmissionLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              electricPowerTransmissionLayer.setOpacity(
                `${0}${"."}` + `${0}` + totalValue
              );
            } else {
              electricPowerTransmissionLayer.setOpacity(
                `${0}${"."}` + totalValue
              );
            }
            this.setState({
              legendElectricPowerTransmissionValue: sliderValue,
            });
          } else if (e.currentTarget.id === "legendMeanWindPowerDensity") {
            if (totalValue === 100) {
              meanWindPowerDensityLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              meanWindPowerDensityLayer.setOpacity(
                `${0}${"."}` + `${0}` + totalValue
              );
            } else {
              meanWindPowerDensityLayer.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legendMeanWindPowerDensityValue: sliderValue });
          } else if (e.currentTarget.id === "legendOffshoreWindAppInPlace") {
            if (totalValue === 100) {
              offshoreWindAppInPlaceLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              offshoreWindAppInPlaceLayer.setOpacity(
                `${0}${"."}` + `${0}` + totalValue
              );
            } else {
              offshoreWindAppInPlaceLayer.setOpacity(`${0}${"."}` + totalValue);
            }
            this.setState({ legendOffshoreWindAppInPlaceValue: sliderValue });
          } else if (
            e.currentTarget.id === "legendOffshoreWindScopingPotential"
          ) {
            if (totalValue === 100) {
              offshoreWindScopingPotentialLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              offshoreWindScopingPotentialLayer.setOpacity(
                `${0}${"."}` + `${0}` + totalValue
              );
            } else {
              offshoreWindScopingPotentialLayer.setOpacity(
                `${0}${"."}` + totalValue
              );
            }
            this.setState({
              legendOffshoreWindScopingPotentialValue: sliderValue,
            });
          } else if (e.currentTarget.id === "legendOffshoreWindTurbineEurope") {
            if (totalValue === 100) {
              offshoreWindTurbineEuropeLayer.setOpacity(1);
            } else if (sliderValue > 90) {
              offshoreWindTurbineEuropeLayer.setOpacity(
                `${0}${"."}` + `${0}` + totalValue
              );
            } else {
              offshoreWindTurbineEuropeLayer.setOpacity(
                `${0}${"."}` + totalValue
              );
            }
            this.setState({
              legendOffshoreWindTurbineEuropeValue: sliderValue,
            });
          }
        };
        //Legend Slider Change for (Well and Geothermal Prospector Layers) Function  End
      }
    );
  }
  render() {
    const layerBoxStyle = {
      width: this.state.layerObject.layerWidth,
      float: this.state.layerObject.layerFloat,
      height: this.state.layerObject.layerHeight,
      backgroundColor: "white",
      overflowY: "auto",
      overflowX: "hidden",
    };
    const mapBoxStyle = {
      width: this.state.layerObject.mapBoxWidth,
      float: this.state.layerObject.layerFloat,
      position: "relative",
    };

    return (
      <>
        <div style={layerBoxStyle} className="arlas-layerbox">
          <button
            style={{
              position: "absolute",
              zIndex: "100",
              height: "60px",
              backgroundColor: "white",
              border: "none",
              left: this.state.layerObject.layerArrowLeft,
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
            onClick={this.toggleLayer}
          >
            <img src={this.state.layerObject.layerArrow} />
          </button>
          <div style={{ display: this.state.layerObject.layerList }}>
            <div className="datatitle">
              <h1
                className={` ${
                  this.state.layerTabData ? "datatitle-active" : null
                }`}
                onClick={() => {
                  this.dataLayersTabSwitch("data");
                }}
              >
                Data Layers
              </h1>
              <h1
                className={` ${
                  this.state.legendTabData ? "datatitle-active" : null
                }`}
                onClick={() => {
                  this.dataLayersTabSwitch("legend");
                }}
              >
                Legend
              </h1>
            </div>
            <div className="databufferblock"></div>
            <ul className="datalist" style={{ display: this.state.layerBlock }}>
              <li>
                TGS Wind
                <ul>
                  <li>
                    <label className="arlas-checkbox" ref={this.windFarmDatabasegreyOutRef}>
                      WindFarm Location Points
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="windFarmDatabase"
                        onChange={(e) => this.fnaddwindFarmDatabaseChange(e)}
                        defaultChecked
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <label className="arlas-checkbox" ref={this.windTurbineDatabasegreyOutRef}>
                      US Wind Turbine Database
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="windTurbineDatabase"
                        onChange={(e) => this.fnaddwindTurbineDatabaseChange(e)}
                        defaultChecked
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="arlas-checkbox" ref={this.electricPowerTransmissiongreyOutRef}>
                      Transmission Lines
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="electricPowerTransmission"
                        onChange={(e) =>
                          this.fnaddelectricPowerTransmissionChange(e)
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <label className="arlas-checkbox" ref={this.offshoreWindAIOUCgreyOutRef}>
                      Offshore Wind Active Licenses
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="offshoreWindAIOUC"
                        onChange={(e) => this.fnoffshoreWindAIOUCLayerChange(e)}
                        defaultChecked
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="arlas-checkbox" ref={this.offshoreWindAppInPlacegreyOutRef}>
                      Offshore Wind License Applications
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="OffshoreWindAppInPlace"
                        onChange={(e) => this.fnoffshoreWindAppInPlaceChange(e)}
                        defaultChecked
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="arlas-checkbox" ref={this.offshoreWindPFLALayergreyOutRef}>
                      Offshore Wind Planned Future License Areas
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="offshoreWindPFLA"
                        onChange={(e) =>
                          this.fnaddoffshoreWindPFLALayerChange(e)
                        }
                        defaultChecked
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <label className="arlas-checkbox"  ref={this.offshoreWindScopingPotentialgreyOutRef}>
                      Offshore Wind Scoping Areas
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="OffshoreWindScopingPotential"
                        onChange={(e) =>
                          this.fnoffshoreWindScopingPotentialChange(e)
                        }
                        defaultChecked
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <label className="arlas-checkbox" ref={this.offshoreWindTurbineEuropegreyOutRef}>
                      European Offshore Wind Turbine Locations
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="OffshoreWindTurbineEurope"
                        onChange={(e) =>
                          this.fnaddoffshoreWindTurbineEuropeChange(e)
                        }
                        defaultChecked
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <label className="arlas-checkbox" ref={this.meanWindPowerDensitygreyOutRef}>
                      Power Density 100m
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="MeanWindPowerDensity"
                        onChange={(e) =>
                          this.fnaddMeanWindPowerDensityChange(e)
                        }
                        defaultChecked
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </li>
              <li>
                Data Types
                <ul>
                  <li>
                    <label className="arlas-checkbox">
                      Well Data
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="addWellCacheLayer"
                        onChange={(e) => this.addWellChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <label className="arlas-checkbox">
                      Sedimentary Basins
                      <input
                        type="checkbox"
                        name="tgsBasins"
                        id="tgsBasins"
                        onChange={(e) => this.tgsBasinsChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <label className="arlas-checkbox">
                      2D Seismic
                      <input
                        type="checkbox"
                        name="2Dseismic"
                        id="2DprogressSurveyLines"
                        onChange={(e) => this.progress2DSurveyLinesChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                  <li>
                    <label className="arlas-checkbox">
                      3D Seismic
                      <input
                        type="checkbox"
                        name="3Dseismic"
                        id="3DprogressSurveyLines"
                        onChange={(e) => this.progress3DSurveyLinesChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="arlas-checkbox">
                      Aero Gravity &amp; Magnetics
                      <input
                        type="checkbox"
                        name="2Dseismic"
                        id="2DotherSurveyLines"
                        onChange={(e) => this.progress2DSurveyLinesChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="arlas-checkbox">
                      Basin Temperature Models (BTM)
                      <input
                        type="checkbox"
                        name="graphicType"
                        id="btmOtherSurveyPrograms"
                        onChange={(e) => this.btmLayerChange(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>

            <div
              className="legendarea"
              style={{ display: this.state.legendBlock }}
            >
              <ul className="datalist">
                <li style={{ display: this.state.wellLegendSlider }}>
                  <h3>Well Data</h3>
                  <div id="wellLayerLegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="wellLegend"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={this.state.wellSliderValue}
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.wellSliderValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li style={{ display: this.state.legendTgsBasinSlider }}>
                  <h3>Sedimentary Basins</h3>
                  <div id="basinLayerLegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="basinsLegend"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={this.state.legendTgsBasinSliderValue}
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendTgsBasinSliderValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  style={{ display: this.state.legend2DInProgressSurveyLines }}
                >
                  <h3>2D Seismic</h3>
                  <div id="SeismicLayer7LegendDiv"></div>
                  <div id="SeismicLayer8LegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="legend2DInProgressSurveyLines"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={
                          this.state.legend2DInProgressSurveyLinesValue
                        }
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legend2DInProgressSurveyLinesValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  style={{ display: this.state.legend2DInCompletedSurveyLines }}
                >
                  <h3>Completed 2D Survey Lines</h3>
                  <p
                    className="legenddataline"
                    style={{ backgroundColor: "#666666" }}
                  >
                    <span>Completed Survey Lines</span>
                  </p>
                  <ul>
                    <li>
                      <input
                        id="legend2DInCompletedSurveyLines"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={
                          this.state.legend2DInCompletedSurveyLinesValue
                        }
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legend2DInCompletedSurveyLinesValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li style={{ display: this.state.legend2DOtherSurveyLines }}>
                  <h3>Aero Gravity &amp; Magnetics</h3>
                  <div id="SeismicLayer9LegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="legend2DOtherSurveyLines"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={this.state.legend2DOtherSurveyLinesValue}
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legend2DOtherSurveyLinesValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  style={{ display: this.state.legend3DInProgressSurveyLines }}
                >
                  <h3>3D Seismic</h3>
                  <div id="SeismicLayer3D1LegendDiv"></div>
                  <div id="SeismicLayer3D5LegendDiv"></div>
                  <div id="SeismicLayer3D3LegendDiv"></div>

                  <ul>
                    <li>
                      <input
                        id="legend3DInProgressSurveyLines"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={
                          this.state.legend3DInProgressSurveyLinesValue
                        }
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legend3DInProgressSurveyLinesValue}%
                      </span>
                    </li>
                  </ul>
                </li>
                <li style={{ display: this.state.legend3DCompletedSurvey }}>
                  <h3>Completed 3D Seismic Surveys</h3>
                  <p
                    className="legenddataboxgeo"
                    style={{ border: "Solid 3px #B1251E" }}
                  >
                    <span>Completed Seismic Surveys(Outline)</span>
                  </p>
                  <p
                    className="legenddataboxgeo"
                    style={{ backgroundColor: "#FA7530" }}
                  >
                    <span>Completed Seismic Surveys(Fill)</span>
                  </p>

                  <ul>
                    <li>
                      <input
                        id="legend3DCompletedSurvey"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={this.state.legend3DCompletedSurveyValue}
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legend3DCompletedSurveyValue}%
                      </span>
                    </li>
                  </ul>
                </li>
                <li style={{ display: this.state.legendBTMmodel }}>
                  <h3>Basin Temperature Models (BTM)</h3>
                  <div id="SeismicBTMLayerLegendDiv"></div>
                  {/* <p
                    className="legenddataboxgeo"
                    style={{ backgroundColor: "#FDFD86" }}
                  >
                    <span>Selected BTM Permian Polygon </span>
                  </p> */}
                  <ul>
                    <li>
                      <input
                        id="legendBTMmodel"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={this.state.legendBTMmodelValue}
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendBTMmodelValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li style={{ display: this.state.legendWindFarmDatabase }}>
                  <h3>WindFarm Location Points</h3>
                  <div id="winfarmLegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="legendWindFarmDatabase"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={this.state.legendWindFarmDatabaseValue}
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendWindFarmDatabaseValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li style={{ display: this.state.legendWindTurbineDatabase }}>
                  <h3>US Wind Turbine Database</h3>
                  {/* <div id="winTurbineLegendDiv"></div> */}
                  <p
                    className="legenddataboxgeo"
                    style={{
                      backgroundColor: "#F3DEFF",
                      borderRadius: "50%",
                      width: "15px",
                      height: "15px",
                      marginTop: "20px",
                      border: "solid 2px #680054",
                    }}
                  >
                    {/* <span style={{ left: "20px", top: "-5px" }}>
                      Wind Turbine Database
                    </span> */}
                  </p>
                  <ul>
                    <li>
                      <input
                        id="legendWindTurbineDatabase"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={this.state.legendWindTurbineDatabaseValue}
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendWindTurbineDatabaseValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  style={{
                    display: this.state.legendElectricPowerTransmission,
                  }}
                >
                  <h3>Transmission Lines</h3>
                  <div id="PowerTransmissionLegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="legendElectricPowerTransmission"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={
                          this.state.legendElectricPowerTransmissionValue
                        }
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendElectricPowerTransmissionValue}%
                      </span>
                    </li>
                  </ul>
                </li>
                <li style={{ display: this.state.legendOffshoreWindAIOUC }}>
                  <h3>Offshore Wind Active Licenses</h3>
                  <div id="OffshoreWindAIOUCLegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="legendOffshoreWindAIOUC"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={this.state.legendOffshoreWindAIOUCValue}
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendOffshoreWindAIOUCValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  style={{ display: this.state.legendOffshoreWindAppInPlace }}
                >
                  <h3>Offshore Wind License Applications</h3>
                  <div id="OffshoreWindAppInPlaceLegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="legendOffshoreWindAppInPlace"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={
                          this.state.legendOffshoreWindAppInPlaceValue
                        }
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendOffshoreWindAppInPlaceValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li style={{ display: this.state.legendOffshoreWindPFLA }}>
                  <h3> Offshore Wind Planned Future License Areas</h3>
                  <div id="OffshoreWindPFLALegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="legendOffshoreWindPFLA"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={this.state.legendOffshoreWindPFLAValue}
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendOffshoreWindPFLAValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  style={{
                    display: this.state.legendOffshoreWindScopingPotential,
                  }}
                >
                  <h3>Offshore Wind Scoping Areas</h3>
                  <div id="OffshoreWindScopingPotentialLegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="legendOffshoreWindScopingPotential"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={
                          this.state.legendOffshoreWindScopingPotentialValue
                        }
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendOffshoreWindScopingPotentialValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  style={{
                    display: this.state.legendOffshoreWindTurbineEurope,
                  }}
                >
                  <h3>European Offshore Wind Turbine Locations</h3>
                  {/* <div id="EuropeLegendDiv"></div> */}
                  <p
                    className="legenddataboxgeo"
                    style={{
                      backgroundColor: "#E1CDDD",
                      borderRadius: "50%",
                      width: "15px",
                      height: "15px",
                      marginTop: "20px",
                      border: "solid 2px #853174",
                    }}
                  >
                    {/* <span style={{ left: "20px", top: "-5px" }}>
                      Turbine Locations (Europe)
                    </span> */}
                  </p>
                  <ul>
                    <li>
                      <input
                        id="legendOffshoreWindTurbineEurope"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={
                          this.state.legendOffshoreWindTurbineEuropeValue
                        }
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendOffshoreWindTurbineEuropeValue}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li style={{ display: this.state.legendMeanWindPowerDensity }}>
                  <h3>Power Density 100m Units(W/m²)</h3>
                  <div id="MeanWindPowerDensityLegendDiv"></div>
                  <ul>
                    <li>
                      <input
                        id="legendMeanWindPowerDensity"
                        className="legendslider"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={
                          this.state.legendMeanWindPowerDensityValue
                        }
                        onChange={this.legendSliderChange}
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                      >
                        {this.state.legendMeanWindPowerDensityValue}%
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          style={mapBoxStyle}
          className="arlas-cs-map map-cursor"
          ref={this.mapRef}
        >
          <div className="mapleft-tools-wrapper">
            <div className="map-action">
              <ul className="ma-right-action">
                <div
                  id="toolstatedisable"
                  style={{
                    position: "absolute",
                    width: "48px",
                    height: "100px",
                    zIndex: 100,
                    display: "none",
                  }}
                ></div>

                {/* <li
                  id="drawid"
                  className="ma-default"
                  ref={this.lineRef}
                  style={{ backgroundColor: this.state.drawIconState }}
                >
                  <span className="ma-straight-ico">
                    <div>&nbsp;</div>
                  </span>
                </li> */}

                {/* <li
                  id="drawBoundingBox"
                  className="esri-widget--button esri-widget esri-interactive"
                  ref={this.boundingBoxRef}
                  style={{ backgroundColor: this.state.drawBoundingIconState }}
                >
                  <span className="esri-icon-checkbox-unchecked boundingBox">
                    <div>&nbsp;</div>
                  </span>
                </li> */}

                {/* <li
                  className="ma-default"
                  id="deleteMapLine"
                  ref={this.deletelineRef}
                >
                  <span className="ma-delete-ico">
                    <div>&nbsp;</div>
                  </span>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="map-tools-wrapper sf-map-view-function sf-map-view-function-true">
          <div className="map-action">
            <ul className="ma-center-action">
              <div
                className={
                  this.state.showBaseMap !== false ? "d-block" : "d-none"
                }
                style={{
                  position: "absolute",
                  backgroundColor: "white",
                  width: "300px",
                  height: "340px",
                  zIndex: "1000",
                  right: "6px",
                  bottom: "65px",
                }}
              >
                <div id="basemapGalleryDiv"></div>
              </div>

              <li className="ma-default" onClick={(e) => this.changeBaseMap(e)}>
                <span className="esri-collapse__icon esri-icon-basemap">
                  <div>&nbsp;</div>
                  <span className="ma-tooltip">Change Basemap</span>
                </span>
              </li>
            </ul>
            <ul className="ma-center-action" style={{ right: "6rem" }}>
              <li
                className="ma-default"
                ref={this.defaultZoomref}
                onClick={this.defaultZoomView}
              >
                <span className="ma-default-ico">
                  <div>&nbsp;</div>
                  <span className="ma-tooltip">Default View</span>
                </span>
              </li>
            </ul>

            <ul className="ma-left-action" style={{ right: "12rem" }}>
              <li
                className="ma-minus"
                ref={this.customZoomOut}
                onClick={this.zoomSingleMinus}
              >
                <span className="ma-minus-ico">
                  <div>&nbsp;</div>
                  <span className="ma-tooltip">Zoom Out</span>
                </span>
              </li>
              <li
                id="mascaleselected"
                className={this.state.zoomState !== true ? "d-block" : "d-none"}
                onClick={this.zoomPanelToggle}
              >
                <span href="#" className="ma-scale-count" id="mascalecount">
                  <span className="masc-value" id="mascvalue">
                    {this.state.zoomItemText}
                  </span>
                  <div>&nbsp;</div>
                  <span className="ma-tooltip">
                    Click Here For More Zoom Levels
                  </span>
                </span>
              </li>
              <li
                className={
                  this.state.zoomState !== true
                    ? "ma-scale-container d-none"
                    : "ma-scale-container d-block"
                }
                id="mascalecontainer"
              >
                <ul className="ma-scale">
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>3</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>4</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>5</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>6</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>7</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>8</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>9</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>10</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>11</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>12</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>13</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>14</span>
                  </li>
                  <li className="zoomvalue" onClick={this.zoomSingleItem}>
                    <span>15</span>
                  </li>
                </ul>
              </li>
              <li
                className="ma-plus"
                ref={this.customZoomIn}
                onClick={this.zoomSinglePlus}
              >
                <span className="ma-plus-ico">
                  <div>&nbsp;</div>
                  <span className="ma-tooltip">Zoom In</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}
