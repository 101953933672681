import React, { useRef, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withAuth } from "@okta/okta-react";
import Logo from "../assets/images/2024_TGS_logo_white.png";
import userIcon from "../assets/images/icons/userIcon.svg";
import useOutsideClick from "../components/useOutsideClick";
import config from "../configs/appSettings";
import arrowClose from "../assets/images/icons/arrow-close.svg";
/* eslint-disable */

const AppHeader = (props) => {
  const [loginState, setloginState] = useState(false);
  const logoutRef = useRef();
  const logoutIconRef = useRef();

  const logoutHandle = async () => {
    try {
      localStorage.clear();
      await props.auth.logout("/");
    } catch (err) {
      props.history.push("/");
    }
  };

  const homelink = () => {
    props.history.push("/");
  };

  useOutsideClick(logoutIconRef, () => {
    logoutRef.current.style.display = "none";
  });

  const logoutMenu = (e) => {
    if (logoutRef.current.style.display === "none") {
      logoutRef.current.style.display = "block";
    } else {
      logoutRef.current.style.display = "none";
    }
  };
  const loginWind = (e) => {
    props.history.push("/map");
  };
  const helpGuideWind = (e) => {
    e.preventDefault();
    const url = `${config.endpoints.HelpGuideurl}`;
    window.open(url, "_blank");
  };

  const userInfo = async () => {
    let authenticated = await props.auth.isAuthenticated();
    if (authenticated) {
      try {
        let userDetails = await props.auth.getUser();
        localStorage.setItem("userEmail", userDetails.email);
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    userInfo();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setloginState(localStorage.getItem("userEmail"));
    }, 1000);
  }, [localStorage.getItem("userEmail")]);

  let logoPath = window.location.protocol + "//" + window.location.host + Logo;

  return (
    <header className="arlas-cs-header-section">
      <div className="logo-section">
        <a
          className="tgs-logo"
          style={{ cursor: "pointer" }}
          onClick={homelink}
        >
          <img src={logoPath} alt="TGS logo" />
        </a>
        <span className="arlas-cs-title">Wind Pathfinder</span>
        <div className="arlas-logout">
          {loginState !== null && loginState !== false ? (
            <div className="btn-group userIconGroup">
              <img
                className="userIcon"
                src={userIcon}
                alt="userIcon"
                onClick={logoutMenu}
                ref={logoutIconRef}
              />

              <div
                style={{ display: "none" }}
                className="dropdown-menu"
                ref={logoutRef}
              >
                <a className="dropdown-item">
                  {localStorage.getItem("userEmail")}
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={logoutHandle}
                  id="logoutClickHandler"
                >
                  Logout
                </a>
              </div>
            </div>
          ) : null}

          {loginState === null ? (
            <div className="btn-group userIconGroup">
              <p
                style={{
                  position: "relative",
                  top: "-10px",
                  lineHeight: "18px",
                  //left: "-54px",
                  left: "56px",
                }}
              >
                First Visit? Check out our guide to explore the full <br />
                capabilities of the Pathfinder
                <span
                  style={{
                    position: "absolute",
                    right: "-20px",
                    top: "10px",
                    
                  }}
                >
                  <img src={arrowClose} alt="arrow close" />
                </span>
              </p>

              <button
                id="windHelpGuide"
                className="arlas-cs-btn primary"
                onClick={helpGuideWind}
                style={{
                  marginTop: "-12px",
                  position: "relative",
                  //right: "15px",
                  right: "-90px",
                  cursor: "pointer",
                  height: "40px",
                }}
              >
                Help Guide
              </button>
            </div>
          ) : null}

          {loginState === null ? (
            <div className="btn-group userIconGroup d-none">
              <button
                id="geoLogin"
                className="arlas-cs-btn primary"
                onClick={loginWind}
                style={{
                  marginTop: "-12px",
                  position: "relative",
                  right: "-87px",
                }}
              >
                Login
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default withRouter(withAuth(AppHeader));
